import { cva } from 'cva';
import React from 'react';

import { Container } from '../../sections/Container';
import { GridCard } from '../GridCard';

const gridItemCva = cva('col-span-1 flex w-full rounded-3xl', {
  variants: {
    index: {
      '0': 'prose-h3:text-5xl tablet:row-span-3 desktop:col-span-2 desktop:row-span-2',
      '1': 'prose-h3:text-5xl tablet:row-span-1 desktop:col-span-2 desktop:row-span-1',
      '2': 'prose-h3:text-2xl prose-h3:leading-extra-loose tablet:row-span-1 desktop:col-span-1 desktop:row-span-1 desktop:prose-h3:text-5xl desktop:prose-h3:leading-loose',
      '3': 'prose-h3:text-2xl prose-h3:leading-extra-loose tablet:row-span-1 desktop:col-span-1 desktop:row-span-1 desktop:prose-h3:text-5xl desktop:prose-h3:leading-loose',
      '4': 'prose-h3:text-5xl desktop:col-span-2 desktop:row-span-1',
      '5': 'prose-h3:text-5xl desktop:col-span-2 desktop:row-span-1',
    },
  },
  compoundVariants: [
    {
      index: ['0', '2', '5'],
      className: 'border-2 border-surface-200',
    },
    {
      index: ['3', '4'],
      className: 'bg-accent-500',
    },
    {
      index: ['1'],
      className: 'bg-surface-100',
    },
  ],
});

const imageCva = cva('col-span-1 flex w-full rounded-3xl', {
  variants: {
    index: {
      '0': 'tablet:w-[150%] tablet:max-w-[150%] desktop:w-full desktop:max-w-full rtl:self-end',
      '1': '',
      '2': 'w-1/2 max-desktop:!pr-0 desktop:w-full rtl:self-end',
      '3': 'w-1/2 self-end max-desktop:!pr-0 desktop:w-full rtl:self-start',
      '4': '',
      '5': '',
    },
  },
});

type TGridCardsProps = {
  banners: React.ComponentProps<typeof GridCard>[];
};

export const GridCards: React.FC<TGridCardsProps> = props => {
  const { banners } = props;

  return (
    <section className='scrollbar-hidden flex'>
      <Container>
        <div className='grid-auto-rows-[minmax(200px,auto)] grid grid-cols-1 gap-2 tablet:grid-cols-2 tablet:gap-4 desktop:grid-cols-4'>
          {banners.map((banner, index) => (
            <GridCard
              key={index}
              className={gridItemCva({
                index: String(index) as '0' | '1' | '2' | '3' | '4' | '5',
              })}
              cardImageClassName={imageCva({
                index: String(index) as '0' | '1' | '2' | '3' | '4' | '5',
              })}
              {...banner}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};
